import React from "react"
import Layout from "../components/layout"

export default function Contact({ data }) {
  return (
    <Layout>
      <section className="section">
        <div className="container">
          <h2 className="title is-2 has-text-tertiary">404</h2>
          <p>Page not found.</p>
        </div>
      </section>
    </Layout>
  );
}